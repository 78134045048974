import React from 'react';

import styles from './FAQBlock.css';

export const FAQBlock = React.memo(() => {
    return (
        <div className={styles.container}>
            Have questions?{' '}
            <a
                className={styles.link}
                href="https://support.arkadium.com/en/support/solutions/folders/44001245243"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="FAQ"
            >
                Please visit the FAQ
            </a>
        </div>
    );
});
