import React, { MouseEventHandler, useEffect, useState, useMemo, useCallback } from 'react';

import classNames from 'classnames';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';
import { batch, useDispatch, useSelector } from 'react-redux';

import styles from './Header.css';
import { StringUtils } from '../../../utils/StringUtils';
import { I18nText } from '../../atoms/i18nText/i18nText';
import { ArkadiumFullLogo } from '../../atoms/Icons/Styleguide/ArkadiumLogo/ArkadiumLogo';
import { DividerIcon } from '../../atoms/Icons/Styleguide/DividerIcon';
import { ArkadiumIcon } from '../../atoms/Icons/Styleguide/MobileNavigationIcons/ArkadiumLogo';
import { getHeaderMenuLinks } from '../../constants/HeaderMenuLinks';
import { HeaderSideMenuTabs } from '../../constants/HeaderSideMenuTabs';
import { getHeaderTabButtons } from '../../constants/HeaderTabButtons';
import { PageTypes } from '../../constants/Pages';
import { SignInUpComponents } from '../../constants/SignInUpComponents';
import { AvatarGemsCounter } from '../../molecules/AvatarGemsCounter/AvatarGemsCounter';
import { Analytics } from '../../services/Analytics/Analytics';
import { UrlService } from '../../services/UrlService';
import UserService from '../../services/UserService';
import { setSideMenuActivePage, setSignInUpState } from '../../store/ducks/layout';
import {
  GemsAnalyticsRegistrationLocations,
  setSocialRegistrationLocation,
} from '../../store/ducks/leanplum/lpAnalytics';

const Header = React.memo(() => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(({ user }) => user);
  const activeUserSubscriptions = useSelector(({ activeUserSubscriptions }) => activeUserSubscriptions);
  const currentLang = useSelector(({ currentLang }) => currentLang);
  const currentPageType = useSelector(({ pageType }) => pageType);
  const currentCategoryPageName = useSelector(({ categoryPageName }) => categoryPageName);
  const categoryPromoData = useSelector(({ categoryPromoData }) => categoryPromoData);
  const pageType = useSelector(({ pageType }) => pageType);
  const gameArkadiumSlug = useSelector(({ gameArkadiumSlug }) => gameArkadiumSlug);
  const hideHeader: boolean = pageType === PageTypes.Game;
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const menuLinks = useMemo(() => getHeaderMenuLinks(t, currentLang), [t, currentLang]);
  const tabButtons: TabButtonProps[] = useMemo(() => getHeaderTabButtons(dispatch, currentLang), [dispatch, currentLang]);
  const categoryPromoMenu = useMemo(() => ({
    title: categoryPromoData?.categoryName,
    link: UrlService.createURL(`/${UrlService.getPageLocalizedRoute(currentLang, PageTypes.Category)}/${categoryPromoData?.categorySlug}/`),
    categoryPageName: categoryPromoData?.categoryName,
  }), [categoryPromoData, currentLang]);
  const handleHeaderZoomed = useCallback(debounce(() => {
    const platform = window.navigator.platform;
    const isMac = /^Mac|^i/.test(platform);
    const defaultPixelRatio = isMac && window.devicePixelRatio >= 2 ? 2 : 1;
    const isZoomed = window.devicePixelRatio !== defaultPixelRatio;
    const isMobile = window.innerWidth <= 1024;

    if (isZoomed || isMobile) {
      document.body.setAttribute('__zoom_used', '');
    } else {
      document.body.removeAttribute('__zoom_used');
    }
  }, 200), []);

  useEffect(() => {
    document.body.setAttribute('__new_header', '');
    const adFree = user && activeUserSubscriptions?.length;

    if (adFree) {
      document.body.setAttribute('__adfree', '');
    } else {
      document.body.removeAttribute('__adfree');
    }

    if (adFree && gameArkadiumSlug === 'crystal-collapse-odyssey') {
      document.body.setAttribute('__is_game_removed', '');
    } else {
      document.body.removeAttribute('__is_game_removed');
    }

    handleHeaderZoomed();
    window.addEventListener('keyup', handleHeaderZoomed);
    window.addEventListener('resize', handleHeaderZoomed);

    return () => {
      document.body.removeAttribute('__new_header');
      document.body.removeAttribute('__adfree');
      document.body.removeAttribute('__is_game_removed');
      window.removeEventListener('keyup', handleHeaderZoomed);
      window.removeEventListener('resize', handleHeaderZoomed);
    };
  }, [user, activeUserSubscriptions, handleHeaderZoomed]);

  const trackClick = useCallback((category: string, index: number) => {
    Analytics.trackEvent(Analytics.general.topNavClick(category, index));
  }, []);
  const openLoginSidebar = useCallback((signInUpState: SignInUpComponents) => {
    if (signInUpState === SignInUpComponents.SIGN_UP) {
      Analytics.trackEvent(Analytics.profile.createAccountButton());
    }

    batch(() => {
      dispatch(setSignInUpState(signInUpState));
      dispatch(setSideMenuActivePage(HeaderSideMenuTabs.LOG_IN_TAB));
      dispatch(setSocialRegistrationLocation(GemsAnalyticsRegistrationLocations.HEADER_BUTTON));
    });
  }, [dispatch]);
  const ProfileOrActions = useMemo(() => {
    return isUserLoggedIn ? (
        <AvatarGemsCounter />
    ) : (
        <span className={styles.profileButtons}>
        <button className={styles.outline} onClick={() => openLoginSidebar(SignInUpComponents.SIGN_IN)}>
          <I18nText keyName="SIGN_IN" />
        </button>
        <button className={styles.primary} onClick={() => openLoginSidebar(SignInUpComponents.SIGN_UP)}>
          <I18nText keyName="CREATE_A_FREE_PROFILE" className={styles.mobileHidden} />
          <I18nText keyName="CREATE_PROFILE" className={styles.mobileOnly} />
        </button>
      </span>
    );
  }, [isUserLoggedIn, openLoginSidebar]);

  useEffect(() => {
    setIsUserLoggedIn(UserService.isUserLoggedIn());
  }, [user]);

  const isMenuLinkActive = useCallback(
      ({ title, categoryPageName }) => {
        const isHomePage = currentPageType === PageTypes.Home && StringUtils.equalIgnoreCase(title, t('HOME_PAGE'));
        const isCategoryPage =
            [PageTypes.AllCategories, PageTypes.Category].includes(currentPageType as PageTypes) &&
            categoryPageName === currentCategoryPageName;

        return isHomePage || isCategoryPage || (currentPageType === PageTypes.BlogArchive && title === 'blog');
      },
      [currentPageType, currentCategoryPageName, t]
  );
  const shouldMenuItemHighlight = useCallback(
      (menuItem) =>
          pageType === PageTypes.Home && StringUtils.equalIgnoreCase(menuItem.title, t('HOME_PAGE')) ||
          (pageType === PageTypes.AllCategories || pageType === PageTypes.Category) &&
          menuItem.categoryPageName === currentCategoryPageName,
      [pageType, currentCategoryPageName, t]
  );

  return (
      <div className={classNames({ [styles.mobileHidden]: pageType === PageTypes.Game, [styles.hidden]: hideHeader })}>
        <header className={styles.header}>
          <a href={UrlService.createURL('/')} aria-label="Go to the homepage">
            <ArkadiumFullLogo
                textHoverColor="#dc1e34"
                arrowHoverColor="#dc1e34"
                focusable={false}
                ariaHidden
                role="presentation"
                className={styles.mobileHidden}
            />
            <ArkadiumIcon className={classNames(styles.mobileOnly, styles.mobileHomeIcon)} />
          </a>
          {ProfileOrActions}
        </header>
        <nav className={classNames(styles.subHeader)}>
        <span className={styles.categoryLinks}>
          {menuLinks.map((l) => (
              <a
                  key={l.title}
                  className={classNames({ [styles.active]: isMenuLinkActive(l) })}
                  onClick={() => trackClick(l.categoryPageName, 0)}
                  href={l.link}
                  data-title={l.title}
                  data-testid={l.categoryPageName}
              >
                {l.title}
              </a>
          ))}
          {currentLang === 'en' && categoryPromoData && categoryPromoData?.categoryName?.length > 0 && (
              <a
                  key={categoryPromoMenu.title}
                  data-testid={categoryPromoMenu.categoryPageName}
                  className={classNames(shouldMenuItemHighlight(categoryPromoMenu) && styles.active)}
                  onClick={() => trackClick(categoryPromoMenu.categoryPageName, 0)}
                  href={categoryPromoMenu.link}
              >
                {categoryPromoMenu.title}
              </a>
          )}
        </span>
          <span className={styles.sidebarButtons}>
          {tabButtons.map((b) => (
              <React.Fragment key={b.keyName}>
                {b.keyName === 'SIDEBAR_BLOG' && <DividerIcon />}
                <TabButton {...b} />
              </React.Fragment>
          ))}
        </span>
        </nav>
      </div>
  );
});

export default Header;

type TabButtonProps = {
  onClick: MouseEventHandler<any>;
  icon: JSX.Element;
  keyName: string;
  dataElementDescription: string;
};

const TabButton = React.memo(({ onClick, icon, keyName, dataElementDescription }: TabButtonProps) => {
  return (
      <button type="button" onClick={onClick} data-element-description={dataElementDescription}>
        <div role="presentation">{icon}</div>
        <I18nText as="p" keyName={keyName} />
      </button>
  );
});
